<template>
  <div class="email-register">
    <div class="email-register-view">
		<el-form ref="form" :model="form" :rules="rules">
		    <el-form-item prop="email">
				<p class="email-register-view-title">{{$t('email')}}</p>
				<div class="email-register-view-content email-register-view-vcode">
				  <el-input v-model="form.email" :placeholder="$t('registerEmail.emailinput')"></el-input>
				</div>
			</el-form-item>
			<el-form-item prop="vcode">
				<p class="email-register-view-title title-top">{{$t('vCodeText')}}</p>
				<div class="email-register-view-content email-register-view-vcode">
				  <el-input v-model="form.captcha" :placeholder="$t('placeholder') + $t('vCodeText')"></el-input>
				  <el-button type="primary">{{$t('testGetCode')}}</el-button>
				</div>
			</el-form-item>
			<el-form-item prop="password">
				<p class="email-register-view-title title-top">{{$t('password')}}</p>
				<div class="email-register-view-content email-register-view-vcode">
				  <el-input v-model="form.password" :placeholder="$t('placeholder') + $t('password')"></el-input>
				</div>
			</el-form-item>
			<el-form-item prop="vcode">
				<p class="email-register-view-title title-top">{{$t('subpassword')}}</p>
				<div class="email-register-view-content email-register-view-vcode">
				  <el-input v-model="form.verifyPassword" :placeholder="$t('placeholder')+$t('subpassword')"></el-input>
				</div>
			</el-form-item>
			<!-- <el-form-item prop="vcode">
				<p class="email-register-view-title title-top">{{$t('subpassword')}}</p>
				<div class="email-register-view-content email-register-view-vcode">
				  <el-input v-model="subpassword" :placeholder="$t('placeholder')+$t('subpassword')"></el-input>
				</div>
			</el-form-item> -->
			<el-button class="submit-login" type="primary" @click="submitForm">{{$t('register')}}</el-button>
			<div class="agreement">
			  <el-checkbox class="and" v-model="checked">{{$t('agree')}}
			  </el-checkbox>
			  <span @click="showAgreement(1)" style="color: #3875FB; cursor: pointer;">{{$t('userAgreement')}}</span>
			  <span class="and">{{$t('registerEmail.he')}}</span>
			  <span @click="showAgreement(6)" style="color: #3875FB; cursor: pointer;">{{$t('privacyPolicy')}}</span>
			</div>
			<p class="login" @click="changeLogin">{{$t('alreadyHaveAnAccountToRegister')}}</p>
		</el-form>
     <!-- <p class="email-register-view-title">{{$t('email')}}</p>
      <div class="email-register-view-content email-register-view-vcode">
        <el-input v-model="email" :placeholder="$t('registerEmail.emailinput')"></el-input>
      </div> -->
     <!-- <p class="email-register-view-title title-top">{{$t('vCodeText')}}</p>
      <div class="email-register-view-content email-register-view-vcode">
        <el-input v-model="vcode" :placeholder="$t('placeholder') + $t('vCodeText')"></el-input>
        <el-button type="primary">{{$t('testGetCode')}}</el-button>
      </div> -->
     <!-- <p class="email-register-view-title title-top">{{$t('password')}}</p>
      <div class="email-register-view-content email-register-view-vcode">
        <el-input v-model="password" :placeholder="$t('placeholder') + $t('password')"></el-input>
      </div> -->
     <!-- <p class="email-register-view-title title-top">{{$t('subpassword')}}</p>
      <div class="email-register-view-content email-register-view-vcode">
        <el-input v-model="subpassword" :placeholder="$t('placeholder')+$t('subpassword')"></el-input>
      </div> -->
      <!-- <el-button class="submit-login" type="primary">{{$t('register')}}</el-button>
      <div class="agreement">
        <el-checkbox class="and" v-model="checked">{{$t('agree')}}
        </el-checkbox>
        <span @click="showAgreement" style="color: #3875FB; cursor: pointer;">{{$t('userAgreement')}}</span>
        <span class="and">{{$t('registerEmail.he')}}</span>
        <span @click="showAgreement" style="color: #3875FB; cursor: pointer;">{{$t('privacyPolicy')}}</span>
      </div>
      <p class="login" @click="changeLogin">{{$t('alreadyHaveAnAccountToRegister')}}</p> -->
    </div>
	<!-- 协议弹窗 -->
	<AgreementPopup ref="popupAgreement"></AgreementPopup>
  </div>
</template>

<script>
	
import {mobileLogin, emailRegister} from "@/api/User";
import AgreementPopup from "@/components/popup/AgreementPopup.vue"
export default {
  props: {},
  data() {
    return {
      // mobile: '',
      // vcode: '',
      checked: false,
	  form: {
	    // username: "",
	    // mobile: '',
	    // password: '',
	    // verifyPassword: '',
	    // verificationCode: '',
	    // invite_code: '',
	    // email: ''
		email: "",
		captcha: "123456",
		password: "",
		verifyPassword: ""
	  },
	  rules: {
	    // username: [
	    //   {required: true, message: this.$t('placeholder') + this.$t('userName'), trigger: 'blur'}
	    // ],
	    // mobile: [
	    //   {required: true, message: this.$t('placeholder') + this.$t('mobile'), trigger: 'blur'}
	    // ],
		// email: [
		// 	{
		// 		required: true, message: this.$t('placeholder') + this.$t('email'), trigger: 'blur'}
		// 	}
		// ],
	    password: [
	      {required: true, message: this.$t('placeholder') + this.$t('password'), trigger: 'blur'}
	    ],
	    verifyPassword: [
	      {
	        required: true,
	        validator: (rule, value, callback) => {
	          if (value === '') {
	            callback(new Error(this.$t('placeholder') + this.$t('confirm') + this.$t('password')))
	          } else if (value !== this.form.password) {
	            callback(new Error(this.$t('thePasswordEnteredTwiceDoesNotMatch')))
	          } else {
	            callback()
	          }
	        },
	        trigger: 'blur'
	      }
	    ],
	    captcha: [
	      {required: true, message: this.$t('placeholder') + this.$t('vCodeText'), trigger: 'blur'}
	    ],
	    email: [
	      {
	        required: false,
	        validator: async (rule, value, callback) => {
	          if (value == '') {
	            callback(new Error(this.$t('placeholder') + this.$t('email')))
	          } else if (value) {
	            let reg = new RegExp(/^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/)
	            if (!reg.test(value)) {
	              callback(new Error(this.$t('theMailboxFormatIsIncorrect')))
	            }
	          } else {
	            callback()
	          }
	        },
	        trigger: 'blur'
	      }
	    ],
		agreeType: 1
	  },
    }
  },
  components: {
	  AgreementPopup
  },
  computed: {},
  created() {
  },
  mounted() {
  },
  watch: {},
  methods: {
   showAgreement(type) {
     console.log('协议')
     this.agreeType = type
     this.$refs.popupAgreement.open()
     this.$refs.popupAgreement.getAgreement(this.agreeType)
   },
    changeLogin() {
      this.$router.push({
        path: '/login/vcodelogin'
      })
    },
	submitForm() {
	  this.$refs['form'].validate((valid) => {
	    if (valid) {
	      if (this.checked) {
	        emailRegister(this.form).then(res => {
	          console.log('注册成功:', res)
			  // this.$message.success(res.message)
	          this.goLogin()
	        }).catch(err => {
	          console.log('注册失败:', err)
	        })
	      } else {
	        this.$message.warning(this.$t('agreeToTheAgreement'))
	      }
	      this.loading = true
	      console.log(this.checked)
	    } else {
	      return false;
	    }
	  });
	},
  }
}
</script>

<style scoped lang='less'>
.email-register {
  margin-top: 23px;
  padding: 60px;
  background-color: @recommendMusicianListBGColor;
  border-radius: 6px;

  &-view {
    width: 300px;
    margin: 0 auto;

    &-title {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @buttonTextColor;
      line-height: 20px;
    }

    .title-top {
      /* margin-top: 40px; */
    }

    &-content {
      width: 300px;
      height: 42px;
      margin-top: 10px;
      background-color: @loginInputBgColor;
      border-radius: 4px;

      .codice-paese {
        line-height: 42px;
        padding-left: 12px;
        width: 36%;

        ::v-deep .el-dropdown {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: @buttonTextColor;
        }
      }

      .line {
        width: 1px;
        height: 22px;
        background: @buttonTextColor;
        margin: 10px;
      }

      ::v-deep .el-input__inner {
        border: none;
        line-height: 42;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: @buttonTextColor;
        padding: 0;
        background-color: rgba(255, 255, 255, 0);
      }
    }

    &-mobile {
      display: flex;
    }

    &-vcode {
      display: flex;
      justify-content: space-between;

      ::v-deep .el-input__inner {
        padding-left: 12px;
      }
    }

    .submit-login {
      width: 100%;
      margin-top: 40px;
    }

    .agreement {
      margin-top: 12px;

      .and {
        color: @buttonTextColor;
      }
    }

    .login {
      margin-top: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @buttonTextColor;
      line-height: 20px;
      text-align: center;
    }

    ::v-deep .el-button--primary {
      background-color: #3370FF !important;
      border-color: #3370FF !important;

      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
